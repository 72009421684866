// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-v-1-characteristics-js": () => import("./../../../src/templates/v1/characteristics.js" /* webpackChunkName: "component---src-templates-v-1-characteristics-js" */),
  "component---src-templates-v-1-home-not-found-js": () => import("./../../../src/templates/v1/home_not_found.js" /* webpackChunkName: "component---src-templates-v-1-home-not-found-js" */),
  "component---src-templates-v-1-item-characteristic-js": () => import("./../../../src/templates/v1/item-characteristic.js" /* webpackChunkName: "component---src-templates-v-1-item-characteristic-js" */),
  "component---src-templates-v-1-landing-cars-js": () => import("./../../../src/templates/v1/landing-cars.js" /* webpackChunkName: "component---src-templates-v-1-landing-cars-js" */),
  "component---src-templates-v-1-model-js": () => import("./../../../src/templates/v1/model.js" /* webpackChunkName: "component---src-templates-v-1-model-js" */),
  "component---src-templates-v-1-search-js": () => import("./../../../src/templates/v1/search.js" /* webpackChunkName: "component---src-templates-v-1-search-js" */)
}

